<template>
<div>
    <form v-if="me" @submit.prevent="handleSubmit">
        <h3> Registro</h3>
    
        <div class="form-group">
            <label>SKU</label>
            <input type="text" class="form-control" v-model="sku" placeholder="SKU"/>
        </div>

        <div class="form-group">
            <label>SKU Fabricante</label>
            <input type="text" class="form-control" v-model="manufacturer_sku" placeholder="SKU Fabricante"/>
        </div>

        <div class="form-group">
            <label>SKU Homologado</label>
            <input type="text" class="form-control" v-model="manufacturer_homologado" placeholder="SKU Homologado"/>
        </div>


        <div class="form-group">
            <label>Marca </label>
            <input type="text" class="form-control" v-model="manufacturer" placeholder="Marca"/>
        </div>

        <div class="form-group">
            <label>Titulo</label>
            <input type="text" class="form-control" v-model="title" placeholder="Titulo"/>
        </div>


        <div class="form-group">
            <label>Descripción</label>
            <input type="text" class="form-control" v-model="description" placeholder="Descripción"/>
        </div>

        <div class="form-group">
            <label>Moneda (siempre es MXN)</label>
            <input type="text" class="form-control" v-model="currency" placeholder="MXN"/>
        </div>

        <div class="form-group">
            <label>Precio (sin IVA)</label>
            <input type="text" class="form-control" v-model="price" placeholder="0.00"/>
        </div>

        <div class="form-group">
            <label>Stock</label>
            <input type="text" class="form-control" v-model="stock" placeholder="0"/>
        </div>

    
    
        <br><button class="btn btn-primary btn-block">Dar de ALTA</button>
    
    </form>

    <form v-if="!me" @submit.prevent="handleSubmit">
        <h3> Registro </h3>
    
        <div class="form-group">
            <label>No has iniciado sesion</label>
        </div>
    </form>
</div>
</template>
    

<!-- <template>
<div>
    <h3 v-if="me">Bienvenido {{me.name}}</h3>
    <h3 v-if="!me">No has iniciado sesion</h3>
</div>
    </template> -->
    

    

<script>

import {mapGetters} from 'vuex';

import axios from 'axios'

    export default{

            name: 'ConsumoApi',
            computed: {
            ...mapGetters(['me'])
        },
            data(){

                return{
                    sku: '',
                    manufacturer_sku: '',
                    manufacturer: '',
                    manufacturer_homologado: '',
                    title: '',
                    description: '',
                    currency: '',
                    price: '',
                    stock: ''
                }

            },
            methods: {
                async handleSubmit(){

                  await axios.post('addproducto', {

                    sku: this.sku,
                    manufacturer_sku: this.manufacturer_sku,
                    manufacturer: this.manufacturer,
                    manufacturer_homologado: this.manufacturer_homologado,
                    title: this.title,
                    description: this.description,
                    currency: this.currency,
                    price: this.price,
                    stock: this.stock
                    });
                    
                    alert("Producto Agregado Correctamente");
                    this.$router.push('MyHome')    

                }
                

            }
    }

</script>

